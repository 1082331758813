import React, {Component} from 'react';
import {compose} from "../../utils";
import withProductStoreService from "../hoc/with-product-store-service";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {Breadcrumb} from 'uikit-react';
import Spinner from '../spinner';
import {productAddedToCart} from "../../actions";
import "./product-details.css";
import ProductListItem from '../product-list-item';
import getThumbnail from "../../utils/get-thumbnail";


class ProductDetails extends Component {

  state = {
    product_id: undefined,
    offer_id: undefined,
    control_product_id: undefined,
    control_offer_id: undefined,
    data: {
      results: []
    },
    images: {
      results:[]
    }
  };

  onProductAddedToCart = (id, title, image_url, price) => {
    const {productAddedToCart} = this.props;

    UIkit.offcanvas('#cart-offcanvas').show();

    let product = {
      id: parseInt(id),
      title: title,
      image_url: image_url,
      price: price
    };
    productAddedToCart(product);
  };

  onClickFavorite = (offerId) => {
        const {productStoreService} = this.props;
        console.log("Добавляем в избранное");
        productStoreService.addFavorite(offerId)
            .then((data) => {
                console.log('Добавлено в избранное');
            });
    };

  componentDidMount() {
    const { match } = this.props;
    const {product_id, offer_id} = match.params;
    this.setState({
      control_product_id: product_id,
      control_offer_id: offer_id,
    });
    this.updateComponent();

  }

  loadOffersByProductId(product_id, offer_id=undefined){
    const {productStoreService} = this.props;

    productStoreService.getOffers(product_id)
        .then((data) => {
          this.setState({data: data});
          if (offer_id===undefined) {
            let my_offer_id;
            if (data.results.length > 0) {
              my_offer_id = data.results[0].id;
              this.setState({offer_id: my_offer_id});
            }

            if (my_offer_id !== undefined) {
              productStoreService.getOfferImages(my_offer_id)
                .then((data) => {
                  this.setState({images: data})
                });
            }
          }
        });
  }

  loadOffersByOfferId(my_offer_id){
    const {productStoreService} = this.props;

    productStoreService.getOffer(my_offer_id)
        .then((data) => {
          this.setState({data: data, offer_id: my_offer_id});
          if (my_offer_id === undefined) {
            if (data.results.length > 0) {
              my_offer_id = data.results[0].id;
            }
          }


          let product_id = data.results[0].product__id;
          this.setState({product_id: product_id});
          this.loadOffersByProductId(product_id, my_offer_id);


          if (my_offer_id !== undefined) {
            productStoreService.getOfferImages(my_offer_id)
              .then((data) => {
                this.setState({images: data})
              });
          }
        });
  }

  updateComponent(){
    const {match } = this.props;
    const {product_id, offer_id} = match.params;

    let my_offer_id = offer_id;

    window.scrollTo(0, 0);

    if (product_id === undefined){
      this.loadOffersByOfferId(offer_id);
    } else if (offer_id === undefined){
      this.loadOffersByProductId(product_id);
    }
    else {
      this.loadOffersByProductId(product_id, offer_id);
    }



  };


  componentDidUpdate(prevProps, prevState) {
    const {product_id: new_product_id, offer_id: new_offer_id} = this.props.match.params;
    const {product_id, offer_id} = prevProps.match.params;
    if ( (new_product_id !== product_id) || (new_offer_id !== offer_id) )  {
      this.setState({
        control_product_id: product_id,
        control_offer_id: offer_id,
        offer_id: undefined,
        product_id: undefined,
      });
      this.updateComponent();
    }
  }

  render() {
    const {id, old_price, match} = this.props;
    let {title, price, images, productStoreService} = this.props;
    const product_id = this.state.product_id;
    const offer_id = this.state.offer_id;

    let characteristic = [];
    let ch;
    let cht;
    let detail_images = images;

    const images_results = this.state.images.results;
    if (images_results.length > 0) {
      let collect_images = [];

      images_results.map((image) => {
        collect_images.push(image.image_url);
      });

      detail_images = collect_images;

    }

    const results = this.state.data.results;
    let len_offers = 0;
    let comment = "";
    let vendor = "";
    let provider = "";
    let delivery_days = "Cегодня со склада";
    if (results.length > 0) {
      let product_item = results[0];
      if (offer_id !== undefined){

        product_item = results.find(offer => offer.id === parseInt(offer_id));
      }

      let days = product_item.delivery_days;
      vendor = product_item.vendor__title;
      provider = product_item.provider__title;

      if (days === 0){
        delivery_days = "Сегодня со склада";
      } else if (days === 1){
        delivery_days = "Завтра со склада";
      }else if (days === 2){
        delivery_days = "Послезавта со склада";
      } else {
        delivery_days = "Через " + days + " дней со склада";
      }
      len_offers = results.length;

      price = product_item.price;
      title = product_item.title;
      if (product_item.spare_brand__title !== '') {
        characteristic.push({d: "Производитель", v: product_item.spare_brand__title, k: "hv"});
      }
      if (product_item.part_number__title !== '') {
        characteristic.push({d: "Парт №", v: product_item.part_number__title, k: "hp"});
      }
      if (product_item.unit !== '') {
        characteristic.push({d: "Единица", v: product_item.unit, k: "hc"});
      }
      if (product_item.color !== '') {
        characteristic.push({d: "Цвет", v: product_item.color, k: "hcc"});
      }
      if (product_item.side !== '') {
        characteristic.push({d: "Сторона", v: product_item.side, k: "hs"});
      }
      if (product_item.front_back !== '') {
        characteristic.push({d: "Перед/зад", v: product_item.front_back, k: "hf"});
      }
      if (product_item.spare_oem !== '') {
        characteristic.push({d: "OEM", v: product_item.spare_oem, k: "ho"});
      }
      if (product_item.gearbox_number !== '') {
        characteristic.push({d: "Номер КПП", v: product_item.gearbox_number, k: "hg"});
      }
      if (product_item.gearbox_number_alternative !== '') {
        characteristic.push({d: "Номер КПП альтернативный", v: product_item.gearbox_number_alternative, k: "hgg"});
      }
      if (product_item.engine_model !== '') {
        characteristic.push({d: "Модель двигателя", v: product_item.engine_model, k: "hd"});
      }
      if (product_item.comment !== '') {
        characteristic.push({d: "Комментарий", v: product_item.comment, k: "hcom"});
        comment = product_item.comment;
      }
      if (product_item.code !== '') {
        characteristic.push({d: "Код товара", v: product_item.code, k: "hcode"});
      }
    }


    if (title === "") {
      title = <Spinner/>;
    }

    if (characteristic.length === 0) {
      ch = <li key='spinner'><Spinner/></li>;
      cht = <tr key='spinner'><td><Spinner/></td></tr>;

    } else {
      ch = characteristic.map((chTmp) => (
        <li key={chTmp.k}><span className="uk-text-muted">{chTmp.d}: </span><span>{chTmp.v}</span></li>
      ));
      cht = characteristic.map((chTmp) => (

        <tr key={chTmp.k}>
          <th className="uk-width-medium">{chTmp.d}</th>
          <td className="uk-table-expand">{chTmp.v}</td>
        </tr>
      ));

    }

    return (
      <div className="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
        <div className="uk-text-center uk-first-column">

          <h1 className="uk-margin-small-top uk-margin-remove-bottom">{title}</h1></div>
        <div className="uk-grid-margin uk-first-column">
          <div className="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
            <div className="uk-first-column">
              <div className="uk-card uk-card-default uk-card-small tm-ignore-container">
                <div className="uk-grid-small uk-grid-collapse uk-grid-match uk-grid" uk-grid="">
                  <div className="uk-width-1-1 uk-width-expand@m uk-first-column">
                    <div className="uk-grid-collapse uk-child-width-1-1 uk-slideshow uk-grid uk-grid-stack"
                         uk-slideshow="finite: true; ratio: 4:3;" uk-grid="">
                      <div className="uk-first-column">
                        <ul className="uk-slideshow-items" data-uk-lightbox>

                          {
                            detail_images.map((image, index) => {
                              return (
                                <li key={index} className={  (index===0) ? "uk-active uk-transition-active" : '' }>
                                  <a
                                  data-type="image"
                                  className="uk-card-body tm-media-box tm-media-box-zoom"
                                                       href={getThumbnail(image, '1200')}>
                                  <figure className="tm-media-box-wrap"><img src={getThumbnail(image, '600')}
                                                                             alt=""/>
                                  </figure>
                                </a></li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="uk-grid-margin uk-first-column">
                        <div className="uk-card-body uk-flex uk-flex-center">
                          <div className="uk-width-1-2 uk-visible@s">
                            <div uk-slider="finite: true" className="uk-slider">
                              <div className="uk-position-relative">
                                <div className="uk-slider-container">
                                  <ul
                                    className="tm-slider-items uk-slider-items uk-child-width-1-4 uk-grid uk-grid-small"
                                  >

                                    {
                                      detail_images.map((image, index) => {

                                        return (

                                          <li key={index} uk-slideshow-item={index}
                                              className={(index===0) ? 'uk-transition-active uk-active': ""}>
                                            <div className="tm-ratio tm-ratio-1-1"><a
                                              className="tm-media-box tm-media-box-frame">
                                              <figure className="tm-media-box-wrap"><img src={getThumbnail(image, '150')}
                                                                                         alt=""/>
                                              </figure>
                                            </a></div>
                                          </li>


                                        )
                                      })
                                    }


                                  </ul>
                                  <div><a
                                    className="uk-position-center-left-out uk-position-small uk-slidenav-previous uk-icon uk-slidenav uk-invisible"
                                    href="#" uk-slider-item="previous" uk-slidenav-previous="">

                                  </a><a
                                    className="uk-position-center-right-out uk-position-small uk-slidenav-next uk-icon uk-slidenav"
                                    href="#" uk-slider-item="next" uk-slidenav-next="">

                                  </a></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ul className="uk-slideshow-nav uk-dotnav uk-hidden@s">
                            <li uk-slideshow-item="0" className="uk-active"><a href="#"></a></li>
                            <li uk-slideshow-item="1"><a href="#"></a></li>
                            <li uk-slideshow-item="2"><a href="#"></a></li>
                            <li uk-slideshow-item="3"><a href="#"></a></li>
                            <li uk-slideshow-item="4"><a href="#"></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-width-1-2@m tm-product-info">
                    <div className="uk-card-body">
                      <div>{vendor} => {provider}</div>
                      <div className="uk-margin">
                        <div className="uk-grid-small uk-grid" uk-grid="">
                          <div className="uk-flex uk-flex-middle uk-first-column">
                            <ul className="uk-iconnav uk-margin-xsmall-bottom tm-rating">
                              <li><span className="uk-text-warning uk-icon" uk-icon="star"/>
                              </li>
                              <li><span className="uk-text-warning uk-icon" uk-icon="star"/>
                              </li>
                              <li><span className="uk-text-warning uk-icon" uk-icon="star"/>
                              </li>
                              <li><span className="uk-text-warning uk-icon" uk-icon="star"/>
                              </li>
                              <li><span className="uk-text-warning uk-icon" uk-icon="star"/>
                              </li>
                            </ul>
                            <div className="uk-margin-xsmall-left"><a className="uk-text-meta js-scroll-to-description"
                                                                      href="#description"
                                                                      onClick={() => {
                                                                        UIkit.switcher('.js-product-switcher').show(3);
                                                                      }}>(2)</a>
                            </div>
                          </div>
                          {/*
                          <div><span
                            className="uk-label uk-label-danger uk-margin-xsmall-right">trade-in</span></div> */}
                        </div>
                      </div>

                      <div className="uk-margin">
                        <div className="uk-padding-small uk-background-primary-lighten uk-border-rounded">
                          <div className="uk-grid-small uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">
                            <div className="uk-first-column">
                              <del className="uk-text-meta">{old_price} руб</del>
                              <div className="tm-product-price">{price} руб</div>
                            </div>

                            <div className="uk-grid-margin uk-first-column">
                              <div className="uk-grid-small uk-grid" uk-grid="">

                                <div>
                                  <button
                                    onClick={() => {
                                      this.onProductAddedToCart(offer_id, title, images[0], price)
                                    }}
                                    className="uk-button uk-button-primary tm-product-add-button tm-shine js-add-to-cart">Добавить в корзину
                                  </button>
                                </div>
                                <div className="uk-width-auto uk-width-expand@s uk-flex uk-flex-middle uk-text-meta"><a
                                  className="uk-margin-small-right js-add-to js-add-to-favorites tm-action-button-active js-added-to"
                                  uk-tooltip="Добавить в избранное" title="" aria-expanded="false" onClick={() => this.onClickFavorite(offer_id)}><span uk-icon="heart"
                                                                                                         className="uk-icon"/></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="uk-margin">
                        <div className="uk-padding-small uk-background-muted uk-border-rounded">
                          <div className="uk-grid-small uk-child-width-1-1 uk-text-small uk-grid uk-grid-stack"
                               uk-grid="">
                            <div className="uk-first-column">
                              <div className="uk-grid-collapse uk-grid" uk-grid=""><span
                                className="uk-margin-xsmall-right uk-icon uk-first-column" uk-icon="cart"/>
                                <div>
                                  <div className="uk-text-bolder">Доставка</div>
                                  <div className="uk-text-xsmall uk-text-muted">{delivery_days}</div>
                                </div>
                              </div>
                            </div>
                            <div className="uk-grid-margin uk-first-column">
                              <div className="uk-grid-collapse uk-grid" uk-grid=""><span
                                className="uk-margin-xsmall-right uk-icon uk-first-column" uk-icon="location"/>
                                <div>
                                  <div className="uk-text-bolder">Забрать</div>
                                  <div className="uk-text-xsmall uk-text-muted">Можно сегодня</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="uk-margin">
                        <ul className="uk-list uk-text-small uk-margin-remove">
                          {ch}

                        </ul>
                        <div className="uk-margin-small-top"><a className="uk-link-heading js-scroll-to-description"
                                                                href="#description"
                                                                onClick={() => {
                                                                  UIkit.switcher('.js-product-switcher').show(1);
                                                                }}><span
                          className="tm-pseudo">Детальная информация</span><span
                          className="uk-margin-xsmall-left uk-icon"
                          uk-icon="icon: chevron-down; ratio: .75;"/></a></div>
                      </div>


                    </div>
                  </div>
                  <div className="uk-width-1-1 tm-product-description uk-grid-margin uk-first-column" id="description">
                    <header>
                      <nav className="tm-product-nav uk-sticky"
                           uk-sticky="offset: 60; bottom: #description; cls-active: tm-product-nav-fixed;">
                        <ul className="uk-subnav uk-subnav-pill js-product-switcher" uk-switcher="connect: .js-tabs">
                          <li aria-expanded="true" className="uk-active"><a className="js-scroll-to-description"
                                                                            href="#description">Описание</a></li>
                          <li aria-expanded="false"><a className="js-scroll-to-description"
                                                       href="#description">Спецификация</a></li>
                          <li aria-expanded="false"><a className="js-scroll-to-description" href="#description">Предложений
                            <span>({len_offers})</span></a></li>

                        </ul>
                      </nav>
                      {/*<div className="uk-sticky-placeholder" hidden=""></div> */}
                    </header>
                    <div className="uk-card-body">
                      <div className="uk-switcher js-product-switcher js-tabs">
                        <section className="uk-active">
                          <article className="uk-article">
                            <div className="uk-article-body">
                              <p>{comment}</p>
                            </div>
                          </article>
                        </section>
                        <section>
                          <h2>Спецификация</h2>

                          <table className="uk-table uk-table-divider uk-table-justify uk-table-responsive">
                            <tbody>
                            {cht}
                            </tbody>
                          </table>
                        </section>
                        <section>


                          <div

                            className="uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid uk-grid uk-grid-stack uk-grid">
                            {
                              results.map((product) => {

                                let new_product = {...product, offer_id: product.id, id: product_id};


                                return (
                                  <ProductListItem
                                    product={new_product}
                                    key={new_product.offer_id}
                                    onAddedToCart={() => {
                                      this.onProductAddedToCart(new_product.offer_id, new_product.title, new_product.image_url, new_product.price)
                                    }}
                                    onProductDetails={() => {}}
                                    productStoreService={productStoreService}
                                  />
                                )

                              })
                            }

                          </div>

                        </section>
                        <section>
                        </section>
                        <section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="uk-grid-margin uk-first-column">

            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  productAddedToCart,
};

export default compose(
  withProductStoreService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductDetails);


